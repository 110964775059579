<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Obat</b> yang akan Kadaluarsa</div>
      <!-- <b-button
        squared
        variant="success"
        @click="$router.push('/masters/medicine-stock-orders/add')"
        v-if="btn"
        >Tambah</b-button
      > -->
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-end mt-n3">
              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Berdasar Obat"
                    @keyup="filterByName"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>

            <!-- Table -->
            <b-table
              striped
              hover
              class="mt-3"
              :items="items"
              :fields="fields"
              :style="'white-space: nowrap'"
              responsive="sm"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '15%' : '' }"
                />
              </template>
              <template #cell(stock_in_out)="data">
                <i
                  v-if="data.item.stock_in_out == 0"
                  class="fas fa-arrow-down"
                  style="color: green"
                  v-b-tooltip.hover
                  title="Stok Masuk"
                ></i>
                <i
                  v-if="data.item.stock_in_out == 1"
                  class="fas fa-arrow-up"
                  style="color: red"
                  v-b-tooltip.hover
                  title="Stok Keluar"
                ></i>
              </template>
              <template #cell(batch)="data">
                <!-- {{ displayAnesthetist(data.item.anesthetist_display) }} -->
                <ul v-for="batch in data.item.batch_display" :key="batch.id">
                  <li class="ml-5">
                    {{
                      batch.batch_no +
                      ":" +
                      batch.quantity +
                      " " +
                      `exp:(${batch.expired})`
                    }}
                  </li>
                </ul>
                <!-- <span v-if="data.item.anesthetist_display.length < 1">-</span> -->
              </template>
              <template #cell(status)="data">
                {{ data.item.status ? "Sudah diambil" : "Belum diambil" }}
              </template>
              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  v-b-tooltip.hover
                  v-if="data.item.status == 1"
                  title="Sudah di ambil"
                  @click="setUse(data.item.id)"
                  class="mr-1 btn-success"
                  ><i class="fas fa-check px-0"></i
                ></b-button>
                <b-button
                  size="sm"
                  v-b-tooltip.hover
                  v-if="data.item.status == 0"
                  title="Ambil"
                  variant="danger"
                  @click="setUse(data.item.id)"
                  ><i class="fas fa-check px-0"></i
                ></b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import api from "@/core/services/api.service.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { debouncer } from "@/core/modules/Helper.js";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
        batch: "",
        status: "",
      },
      options: [
        { value: "", text: "Cari Berdasar Status", disabled: true },
        { value: 0, text: "Sudah diambil" },
        { value: 1, text: "Belum diambil" },
      ],
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "medicine_name",
          label: "Obat",
          sortable: true,
        },
        {
          key: "batch_no",
          label: "Batch No",
          sortable: true,
        },
        {
          key: "expired",
          label: "Kadaluarsa",
          sortable: true,
        },
        {
          key: "first_quantity",
          label: "Stok Awal",
          sortable: true,
        },
        {
          key: "saldo",
          label: "Sisa Stok",
          sortable: true,
        },
        // { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // user access
      btn: false,
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&batch_no=${this.filter.batch}`;
      let response = await module.paginate(
        "medicine-expireds",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async setUse(id) {
      console.log("set default id", id);
      api.setHeader();
      let result = await api.put("medicine-stock-orders/set-use/" + id);
      if (result) {
        this.pagination();
      }
    },

    filterByName() {
      debouncer(() => {
        this.pagination();
      }, 500);
    },

    async filterByStatus(evt) {
      console.log("action", evt);
      if (!evt) {
        this.filter.status = await "";
        this.pagination();
      } else {
        this.filter.status = await evt;
        this.pagination();
        console.log("ok", this.filter.status);
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("medicine-stock-orders/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        //

        if (access_right[a] == "9002") {
          this.btn = true;
        }
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Peringatan Kadaluarsa" }]);
    // Get Data
    this.pagination();
    this.setActiveMenu();
  },
};
</script>
